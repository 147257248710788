.check-box-container {
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(208, 222, 225, 0.4);
  /* background-color: red; */
}

.radio-container {
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid rgba(208, 222, 225, 0.4);
  border-bottom: 1px solid rgba(208, 222, 225, 0.4);
}

.default-label {
  color: var(--Dark-Gray-100, #2b3746);
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.gray-label {
  color: var(--Gray-70, rgba(85, 99, 115, 0.7));
  font-family: Outfit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.check-box {
  color: aqua;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}

.ant-switch {
  min-width: 26px !important;
  /* height: 22px; */
  /* height: 16px;   */
}
.ant-switch.ant-switch-checked {
  background-color: #4cbbcb;
}

.ant-form-item-row {
  width: 100% !important;
  display: flex;
  align-items: center;
}

.ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ant-form-item-label > label::after {
  content: "";
}
